<template>
  <div class="main-container">
    <div class="header">Page Not Found</div>
    <div class="content">
      <strong>{{ $route.path }}</strong> was not found on this website.
      <br />
      Please check the URL for mistakes and try again. Or navigate to the
    </div>
    <router-link :to="{ name: 'Home' }">
      <div class="button link">Home page</div>
    </router-link>
  </div>
</template>

<script>
export default {
  name: "NotFoundComponent"
};
</script>

<style scoped>
.main-container {
  color: white;
  text-align: center;
}
.header {
  font-size: 40px;
  margin-bottom: 20px;
}
.content {
  font-size: 20px;
  margin-bottom: 20px;
}
.button {
  background-color: var(--bwb-color);
  margin: 15px auto;
  padding: 15px;
  width: 160px;
}
.link {
  color: white;
  font-size: 20px;
}
</style>
